<template>
  <div class="page">
    <BreadCrumb />
    <div class="page-body">
      <QueryModule
        :queryModule="queryModule"
        @queryRes="queryRes"
        @reset="reset"
      >
        <el-col :span="8">
          <el-form-item label="产生来源">
            <el-select  v-model="queryModule.info1">
              <el-option label="手动创建" value="0" />
              <el-option label="评估报告" value="1" />
              <el-option label="告警监控" value="2" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="问题级别">
            <el-select  v-model="queryModule.info2">
              <el-option label="提示告警" value="0" />
              <el-option label="一般告警" value="1" />
              <el-option label="严重告警" value="2" />
              <el-option label="重要告警" value="3" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="关联对象">
            <el-input v-model="queryModule.createBy"></el-input>
          </el-form-item>
        </el-col>
       
      </QueryModule>
      
      <el-row :gutter="20" >
        <el-col :span="6">
          <div class="tree_title">
            <p class="first_class_tittle">知识分类</p>
          </div>
          <el-input v-model="filterText" placeholder="Filter keyword" />
          <div class="tree_box">
            <el-tree
                ref="tree"
                class="filter-tree"
                :data="tree_data"
                :props="defaultProps"
                default-expand-all
                :filter-node-method="filterNode"
              />
          </div>
        </el-col>
        <el-col :span="18">
          <div class="table_title">
            <p class="first_class_tittle">知识列表</p>
          </div>
          <div class="table_box">
            <el-table :data="tableData" stripe border fit v-loading="loading">
              <el-table-column  type="index"  :index="(currentPage - 1) * 5 + 1" label="序号"></el-table-column>
              <el-table-column prop="t1" label="产生来源" width="80"></el-table-column>
              <el-table-column prop="t2" label="关联对象"></el-table-column>
              <el-table-column prop="t3" label="发生时间" show-overflow-tooltip></el-table-column>
              <el-table-column prop="t4" label="问题级别" width="80"></el-table-column>
              <el-table-column prop="t5" label="问题内容" width="220" show-overflow-tooltip></el-table-column>
              <el-table-column prop="t6" label="问题负责人"></el-table-column>
              <el-table-column  label="操作" width="130">
                <template #default="scope">
                <el-tooltip class="item" effect="light" content="修改" placement="top-end">
                  <el-button type="primary" circle  size="mini"  @click="modify(scope.row)" >
                    <el-icon :size="15">
                      <i-edit />
                    </el-icon>
                  </el-button>
                </el-tooltip>
                  <el-popconfirm cancel-button-text="取消" confirm-button-text="确定" icon-color="red" size="mini" title="确定要删除么?" @confirm="deleteWarning(scope.row)">
                    <template #reference>
                      <el-button circle size="mini" title="删除" type="danger">
                        <el-icon>
                          <i-delete />
                        </el-icon>
                      </el-button>
                    </template>
                  </el-popconfirm>
                  <el-tooltip class="item" effect="light" content="知识详情" placement="top-end">
                    <el-button type="primary" circle size="mini" @click="detailsBtn(scope.row)">
                      <el-icon :size="15">
                        <i-fold />
                      </el-icon>
                    </el-button>
                  </el-tooltip>
                  
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleCurrentChange"
              layout="total, prev, pager, next, jumper"
              :page-size="pageSize"
              :total="total"
              v-model:currentPage="currentPage"
              background
              
            >
            </el-pagination>
          </div>
        </el-col>
      </el-row>
      
    </div>
  </div>

</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb'
import QueryModule from '/src/components/QueryModule'
import { mapGetters } from 'vuex'

export default {
  name: 'SystemUser',
  data() {
    return {
      loading: true,
      tableData:[],
      ajaxPage: 0,
      currentPage: 1,
      total: 0,
      dialogModify: false,
      queryModule: {
        createBy: '',
        username: '',
        info1:"",
        info2:"",
        info3:"",
        info4:""
      },
      labelWidth: '120px',
      filterText: '',
      tree_data: [
        {
          id: 1,
          label: 'Level one 1',
          children: [
            {
              id: 4,
              label: 'Level two 1-1',
              children: [
                {
                  id: 9,
                  label: 'Level three 1-1-1',
                },
                {
                  id: 10,
                  label: 'Level three 1-1-2',
                },
              ],
            },
          ],
        },
        {
          id: 2,
          label: 'Level one 2',
          children: [
            {
              id: 5,
              label: 'Level two 2-1',
            },
            {
              id: 6,
              label: 'Level two 2-2',
            },
          ],
        },
        {
          id: 3,
          label: 'Level one 3',
          children: [
            {
              id: 7,
              label: 'Level two 3-1',
            },
            {
              id: 8,
              label: 'Level two 3-2',
            },
          ],
        },
      ],
       defaultProps: {
               children: 'children',
               label: 'label',
             },
    }
  },
  components: {
    BreadCrumb,
    QueryModule
  },
  computed: {
    ...mapGetters(['pageSize'])
  },
  mounted: function () {
    const _this = this
    _this.userList(
      _this.ajaxPage,
      _this.pageSize,
      _this.currentPage,
      _this.queryModule
    )
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  methods: {
    filterNode(value, data) {
          if (!value) return true
          return data.label.indexOf(value) !== -1
        },
    reset() {
      const _this = this
      Object.keys(_this.queryModule).forEach(
        key => (_this.queryModule[key] = '')
      )
      _this.currentPage = 1
      _this.ajaxPage = 0
      _this.userList(
        _this.ajaxPage,
        this.pageSize,
        _this.currentPage,
        _this.queryModule
      )
    },
    queryRes(data) {
      const _this = this
      _this.userList(_this.ajaxPage, this.pageSize, _this.currentPage, data)
    },
    userList(page, size, currentPage, datas) {
      const _this = this
      datas['page'] = page
      datas['size'] = size
      this.$axios.POST('/api/v1/admin/user/all', datas).then(res => {
        _this.loading = false
        let data = res
        if (data.code == 200) {
          _this.tableData = data.data.content;
          console.log(data)
          _this.total = 1;
           _this.tableData=[]
            _this.tableData.push({
              t1: "手工创建",
              t2: "违章安全信息作业",
              t3: "2021-09-15 07:50:58",
              t4: "提示",
              t5: "总量数据 大于等于 阈值 10000 通过校验",
               t6: "datacheck"
            },{
              t1: "评估报告",
              t2: "执法单位作业",
              t3: "2021-09-15 07:50:58",
              t4: "严重",
              t5: "总量数据 大于等于 阈值 10000 通过校验",
               t6: "datacheck"
            });
            
            
        }
      })
    },
    modify(row) {
      console.log(row)
    },
    deleteWarning(row) {
      console.log(row)
    },
    detailsBtn(row){
      console.log(row)
    },
    handleCurrentChange(val) {
      const _this = this
      _this.userList(val - 1, _this.pageSize, val, _this.queryModule)
    }
  },
 
}
</script>

<style scoped>
  
</style>
